import React from "react"
import { Routes, Route } from "react-router-dom"
import { routes } from "../../config/routes-config"
import RouteWrapper from "./RouteWrapper"
import PageNotFound from "../error_pages/NotFound"
import CheckoutCompletePage from "../checkout/CheckoutCompletePage"
import { Grid } from "@mui/material"

const styles = () => ({
  gridContainer: {
    marginTop: 7
  }
})

const Router = () => {
  const classes = styles()
  return (
    <Grid sx={classes.gridContainer}>
      <Routes>
        {routes.map((route) => (
          <Route
            path={route.route}
            element={<RouteWrapper component={route.component} />}
            key={route.key}
          />
        ))}
        <Route path="/checkout/complete" element={<CheckoutCompletePage />} />
        <Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Grid>
  )
}

export default Router
