import { ProductAttributes } from "./Product"

export const calculateTotalQty = ({
  ctnQty,
  pcsQty,
  product
}: {
  ctnQty: number
  pcsQty: number
  product: ProductAttributes
}): number => {
  const ctnToPcs = ctnQty * parseInt(product.sellFactor1)
  return ctnToPcs + pcsQty
}
