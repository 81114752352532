import React from "react"
import { AppBar, Toolbar, Grid } from "@mui/material"
import HeaderButton from "./HeaderButton"
import { useNavigate, useLocation } from "react-router-dom"
import { useTheme, Theme } from "@mui/material/styles"
import logo from "../../assets/logos/colgate-logo.png"
import HomeIcon from "@mui/icons-material/Home"

const styles = (theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main
  },
  logo: {
    marginTop: 4,
    objectFit: "contain"
  } as React.CSSProperties,
  btnContainer: {
    display: "flex",
    padding: 1
  }
})

const MenuAppBar = () => {
  const classes = styles(useTheme())
  const navigate = useNavigate()
  const pathName = useLocation().pathname
  const CHECKOUT_PATH = "/checkout/complete"

  const goToCartPage = () => {
    navigate("/checkout")
  }

  const showCartIcon = () => pathName !== CHECKOUT_PATH

  return (
    <AppBar position="fixed" sx={classes.appBar}>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={4} flexDirection="row" alignItems="center">
            <Grid item>
              <HomeIcon fontSize="large" onClick={() => navigate("/")} />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <img
              width="110"
              height="30"
              src={logo}
              alt="logo_image"
              style={classes.logo}
              onClick={() => navigate("/")}
            />
          </Grid>
          <Grid item xs={4} justifyContent="flex-end" sx={classes.btnContainer}>
            {showCartIcon() ? (
              <HeaderButton handleClick={goToCartPage} />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
export default MenuAppBar
