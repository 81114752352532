import React from "react"
import categoriesList from "./categories.json"
import CategoryCard from "./CategoryCard"
import { Grid, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import CPWebBanner from "../../../assets/images/CP-Web-Banner.jpg"

const styles = () => ({
  categoryList: {
    padding: "0 12px"
  },
  title: {
    padding: 2,
    fontWeight: "bold"
  },
  banner: {
    width: "100%"
  }
})

export interface Category {
  id: string
  name: string
  imgUrl: string
  slug: string
}
const CategoryList = () => {
  const classes = styles()
  const navigate = useNavigate()

  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={CPWebBanner} style={classes.banner} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" sx={classes.title} align="left">
          Categories
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography
          variant="h6"
          sx={classes.title}
          align="right"
          onClick={() => {
            navigate(`/all-products`)
          }}
        >
          All Products
        </Typography>
      </Grid>

      <Grid container sx={classes.categoryList}>
        {categoriesList.categories.map((category: Category) => (
          <CategoryCard key={category.id} category={category} />
        ))}
      </Grid>
    </Grid>
  )
}

export default CategoryList
