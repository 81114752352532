export const addToStorage = (key: string, data: any) => {
  data = JSON.stringify(data)
  localStorage.setItem(key, data)
}

export const getFromStorage = (key: string) => {
  const item: string | null = localStorage.getItem(key)
  if (item) return JSON.parse(item)
  return null
}

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const LocalStorageKeys = Object.freeze({
  waSession: "waSession"
})

export const validateExpiry = (expiry: any) => {
  const currentTime = new Date().getTime()
  if (expiry < currentTime) {
    removeFromStorage(LocalStorageKeys.waSession)
    return false
  }
  return true
}
