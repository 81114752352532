import React from "react"
import { Grid, Typography, CircularProgress } from "@mui/material"

const LabelValue = ({
  title,
  value,
  loading
}: {
  title: string
  value: string
  loading?: boolean
}) => (
  <>
    <Grid item xs={12}>
      <Typography variant="caption">{title}</Typography>
    </Grid>
    {loading ? (
      <CircularProgress size={20} />
    ) : (
      <Grid item xs={12}>
        <Typography variant="subtitle2">{value}</Typography>
      </Grid>
    )}
  </>
)

export default LabelValue
