import React, { useContext, useEffect } from "react"
import { Grid, Typography, Divider } from "@mui/material"
import { useTheme, Theme } from "@mui/material/styles"
import LabelValue from "../../common/LabelValue"
import CartContext from "../context/cart/CartContext"
import { CartContextType } from "../../@types/cartTypes"
import { calculateTotalQty } from "../../model/modelHelpers"
import AmountHelpers from "../../utils/AmountHelpers"

const styles = (theme: Theme) => ({
  productGrid: {
    padding: "0 24px",
    textAlign: "center",
    backgroundColor: "#EFEFEF"
  },
  detail: {
    padding: "4px 2px 4px 2px"
  },
  typography: {
    fontSize: 16,
    fontWeight: 600
  },
  divider: {
    borderColor: theme.palette.secondary.main,
    borderWidth: 0.5
  }
})

const Total = () => {
  const classes = styles(useTheme())
  const cartContext = useContext(CartContext) as CartContextType

  useEffect(() => {
    cartContext.updateTotalCartAttributes({
      tradePrice: parseFloat(calculateTotalTradePrice().toFixed(2)),
      gst: parseFloat(calculateGst().toFixed(2)),
      discount: parseFloat(calculateTotalDiscount().toFixed(2)),
      tradeOffer: parseFloat(calculateTotalTradeOffer().toFixed(2)),
      totalAmount: parseFloat(calculateNetAmount().toFixed(2))
    })
  }, [cartContext.cartItems])

  const calculateTotalTradePrice = () => {
    let total = 0
    cartContext.cartItems.forEach(
      (item) =>
        (total +=
          AmountHelpers().format(parseFloat(item.product.tpWoTax)) *
          calculateTotalQty({
            ctnQty: item.ctnQty,
            pcsQty: item.pcsQty,
            product: item.product
          }))
    )
    return total
  }

  const calculateGst = () => {
    let totalGst = 0
    cartContext.cartItems.forEach((item) => {
      totalGst += item?.tax ? AmountHelpers().format(item.tax) : 0
    })
    return totalGst
  }

  const calculateTotalTradeOffer = () => {
    let totalTradeOffer = 0
    cartContext.cartItems.forEach(
      (item) =>
        (totalTradeOffer += AmountHelpers().format(
          parseFloat(item.tradeOfferDiscount.discount)
        ))
    )
    return totalTradeOffer
  }
  const calculateTotalDiscount = () => {
    let totalDiscount: number = 0
    cartContext.cartItems.forEach(
      (item) =>
        (totalDiscount += item?.discount
          ? AmountHelpers().format(item.discount)
          : 0)
    )

    return AmountHelpers().format(totalDiscount)
  }
  const calculateNetAmount = () => {
    return AmountHelpers().format(
      calculateTotalTradePrice() +
        calculateGst() -
        calculateTotalTradeOffer() -
        calculateTotalDiscount()
    )
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sm={6}
        lg={4}
        sx={classes.productGrid}
        justifyContent="center"
        justifyItems="center"
      >
        <Grid
          container
          item
          xs={12}
          sx={classes.detail}
          justifyContent="center"
        >
          <Grid item>
            <Typography variant="h6">Checkout Summary</Typography>
            <Divider sx={classes.divider} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={classes.detail}
          flexDirection="row"
          wrap="nowrap"
        >
          <Grid item xs={6} justifyContent="center">
            <LabelValue
              title="Trade Price"
              value={`Rs. ${AmountHelpers().formatToCurrency(
                calculateTotalTradePrice()
              )}`}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue
              title="GST"
              value={`Rs. ${AmountHelpers().formatToCurrency(calculateGst())}`}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={classes.detail}
          flexDirection="row"
          wrap="nowrap"
        >
          <Grid item xs={6}>
            <LabelValue
              title="Discount"
              value={`Rs. ${AmountHelpers().formatToCurrency(
                calculateTotalDiscount()
              )}`}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue
              title="Trade Offer"
              value={`Rs. ${AmountHelpers().formatToCurrency(
                calculateTotalTradeOffer()
              )}`}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={classes.detail}
          justifyContent="center"
        >
          <Grid item>
            <LabelValue
              title="Net Amount of GST & TO"
              value={`Rs. ${AmountHelpers().formatToCurrency(
                calculateNetAmount()
              )}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Total
