import Homepage from "../main/homepage/Homepage"
import ProductPage from "../main/products/ProductList"
import CheckoutPage from "../main/checkout/Checkout"
import SomethingWentWrong from "../main/checkout/SomethingWentWrong"
import PrivacyPolicies from "../main/policies/PrivacyPolicies"

export const routes = [
  {
    key: "homepage",
    title: "Homepage",
    route: "/",
    auth_required: true,
    component: Homepage
  },
  {
    key: "searchAllProducts",
    title: "searchAllProducts",
    route: "/all-products/search",
    auth_required: true,
    component: ProductPage
  },
  {
    key: "searchWithCategories",
    title: "SearchWithCategories",
    route: "/category/:category/search",
    auth_required: true,
    component: ProductPage
  },
  {
    key: "products",
    title: "Products",
    route: "/category/:category",
    auth_required: true,
    component: ProductPage
  },
  {
    key: "checkout",
    title: "Checkout",
    route: "/checkout",
    auth_required: true,
    component: CheckoutPage
  },
  {
    key: "somethingWentWrong",
    title: "Something went wrong",
    route: "/checkout/something-went-wrong",
    auth_required: true,
    component: SomethingWentWrong
  },
  {
    key: "allProducts",
    title: "AllProducts",
    route: "/all-products",
    auth_required: true,
    component: ProductPage
  },
  {
    key: "privacyPolicy",
    title: "PrivacyPolicies",
    route: "/privacy-policy",
    auth_required: true,
    component: PrivacyPolicies
  }
]
