import React from "react"
import { Grid, Typography, Link, List, ListItem } from "@mui/material"

const styles = () => ({
  container: {
    padding: 4
  },
  heading: {
    marginBottom: 2
  },
  paragraph: {
    padding: 1
  },
  bold: {
    fontWeight: 900
  }
})

const PrivacyPolicies = () => {
  const classes = styles()

  return (
    <Grid container sx={classes.container}>
      <Grid container item>
        <Grid item xs={12} sx={classes.heading}>
          <Typography variant="h4">
            Your Use of WhatsApp Ordering System – Service Specific Supplement
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              By accessing this service/App/site, you acknowledge and agree to
              all terms, conditions and privacy policies available at{" "}
              <Link
                href="https://colgate.com.pk/privacy-policy"
                variant="body1"
                underline="always"
              >
                https://colgate.com.pk/privacy-policy
              </Link>
              , and otherwise contained on or referenced in this service
              including but not limited to the terms available at{" "}
              <Link
                href="https://www.whatsapp.com/legal/business-solution-terms"
                variant="body1"
                underline="always"
              >
                https://www.whatsapp.com/legal/business-solution-terms
              </Link>
              , which incorporates without limitation, the WhatsApp Business
              Terms of Service (currently available at{" "}
              <Link
                href="https://www.whatsapp.com/legal/business-terms"
                variant="body1"
                underline="always"
              >
                https://www.whatsapp.com/legal/business-terms
              </Link>
              ) (collectively, the “TOS”), as varied by these Terms. For
              clarity, except as expressly stated herein, the TOS applies
              unchanged and in full force and effect to you. In the event of any
              conflict or inconsistency between the provisions of these Terms
              and the provisions of the TOS, the provisions of these Terms will
              control, but only to the extent of such conflict or inconsistency.
              Capitalized terms that are not defined below or in the designated
              places throughout these Terms have the meaning set forth in the
              TOS (the “Agreement”).
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              By your continuing to use the facilities and services{" "}
              <Typography variant="body1" component="b" sx={classes.bold}>
                WhatsApp Ordering System
              </Typography>
              , it is deemed that you are agreeable to the Agreement.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              This statement explains how Colgate-Palmolive (Pakistan) Limited,
              its affiliates (“the{" "}
              <Typography variant="body1" component="b" sx={classes.bold}>
                Group
              </Typography>
              ”) will use and protect your personal information. It applies to
              information held about you now, or at any future date. The ‘data
              controller’ for your personal information is Colgate Palmolive
              (Pakistan) Limited.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              The Group respects the privacy of your personal information and
              will apply strict standards of confidentiality and security. Your
              personal information may be used:
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <List>
              <ListItem>
                <Typography variant="body1">
                  1- to provide and operate any service or product you require;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2- to facilitate the provision of any service or product to a
                  third party for whom you act as guarantor or security
                  provider;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3- to update and enhance your records with us;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  4- to understand your business dealing with us as well as your
                  needs;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  5- to advise you of other products which may be of interest to
                  you;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  6- for any purpose required by law or regulation including
                  fraud prevention;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  7- to monitor our compliance with legal and regulatory
                  requirements and with our internal policy requirements;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  8- to support the Group’s business, financial and risk
                  monitoring, planning and decision making.{" "}
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1"></Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              For the purposes stated above, your personal information may be
              disclosed to any of the following:
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <List>
              <ListItem>
                <Typography variant="body1">
                  1- any office or branch of Colgate Palmolive (Pakistan)
                  Limited, or other Group member including associates or
                  affiliates;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  2- any distributors and shopkeepers; provider;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  3- any agent, contractor or third party service provider, or
                  any professional adviser to the Group;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  4- any guarantor or third party security provider;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  5- any regulatory, supervisory, governmental or
                  quasi-governmental authority with jurisdiction over any Group
                  member;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  6- any person to whom the Group is required or authorised by
                  law or court order to make such disclosure;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  7- any of our actual or potential participant or
                  sub-participant in, assignee, novatee or transferee of, any of
                  the Group’s rights and/or obligations in relation to you;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  8- any other person under a duty of confidentiality to the
                  Group;
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body1">
                  9- any institution with which we have or propose to have
                  dealings.
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1"></Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              The above disclosures may require the transfer of your information
              to parties located in countries that do not offer the same level
              of data protection as your home country. We may transfer such data
              if it is necessary to perform our contact with you. The Group will
              ensure that parties to whom your details are transferred treat
              your information securely and confidentially.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              Your information will be retained by the Group for as long as
              there is a business need to hold the information or as required by
              legal, regulatory, business or accounting requirements or to
              protect the Group’s interests.
            </Typography>
          </Grid>

          <Grid item xs={12} sx={classes.paragraph}>
            <Typography variant="body1">
              Under the laws of some territories (including the UK), you may
              have the right to access information held about you in those
              territories.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PrivacyPolicies
