import React from "react"
import {
  LocalStorageKeys,
  getFromStorage,
  validateExpiry
} from "../../utils/LocalStorage"
import InvalidSessionPage from "../homepage/waSession/InvalidSessionPage"

const RouteWrapper = ({
  component: RouteComponent
}: {
  component: React.FC
}) => {
  const localStorageSession = getFromStorage(LocalStorageKeys.waSession)
  const isAuthenticated =
    localStorageSession?.waSession && validateExpiry(localStorageSession.expiry)
  return isAuthenticated ? <RouteComponent /> : <InvalidSessionPage />
}

export default RouteWrapper
