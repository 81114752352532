import React, { useState, useEffect } from "react"
import { CartAttributes, TotalCartAttributes } from "../../../@types/cartTypes"
import DiscountOfferModel, {
  ProductWithDiscountAndTax
} from "../../../model/discount_offer/DiscountOfferModel"
import { calculateTotalQty } from "../../../model/modelHelpers"
import { SchemeLimit } from "../../../model/offer/OfferModel"
import { ProductAttributes } from "../../../model/Product"
import AmountHelpers from "../../../utils/AmountHelpers"
import { CartProvider } from "./CartContext"

const CartContainer: React.FC<React.ReactNode> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartAttributes[]>([])
  const [totalCartAttributes, setTotalCartAttributes] =
    useState<TotalCartAttributes>({
      tradePrice: 0,
      gst: 0,
      discount: 0,
      tradeOffer: 0,
      totalAmount: 0
    })
  const [schemeLimits, setSchemeLimits] = React.useState<SchemeLimit[]>([])

  useEffect(() => {
    getDiscountAndUpdateCart()
  }, [schemeLimits])

  const updateCartItem = (cartItem: CartAttributes) => {
    const cartItemIndex = getCartItemIndex(cartItem.product.sku)
    const copiedCartItems = JSON.parse(JSON.stringify(cartItems))

    if (cartItem.ctnQty === 0 && cartItem.pcsQty === 0) {
      window.onbeforeunload = null
      return removeCartItem(cartItem.product)
    }

    const newCartItem: CartAttributes = {
      product: cartItem.product,
      ctnQty: cartItem.ctnQty,
      pcsQty: cartItem.pcsQty,
      netAmount: cartItem.netAmount,
      offers: cartItem.offers,
      groupedDiscountOffer: cartItem.groupedDiscountOffer,
      tradeOfferDiscount: cartItem.tradeOfferDiscount,
      tax: cartItem?.tax ?? 0,
      discount: cartItem?.discount ?? 0
    }
    window.onbeforeunload = () =>
      "Are you sure you want to leave? You are in the middle of something."
    if (cartItemIndex >= 0) {
      copiedCartItems[cartItemIndex] = newCartItem
      setCartItems(copiedCartItems)
      return
    }
    setCartItems([...copiedCartItems, newCartItem])
    getDiscountAndUpdateCart([...copiedCartItems, newCartItem])
  }

  const updateTotalCartAttributes = (totalItems: TotalCartAttributes) => {
    const newTotalCartAttributes: TotalCartAttributes = {
      tradePrice: totalItems.tradePrice,
      gst: totalItems.gst,
      discount: totalItems.discount,
      tradeOffer: totalItems.tradeOffer,
      totalAmount: totalItems.totalAmount
    }

    setTotalCartAttributes(newTotalCartAttributes)
  }

  const getCartItemIndex = (
    productSku: string,
    updatedItems?: CartAttributes[]
  ) => {
    const items = updatedItems ?? cartItems
    return items.findIndex((item) => item.product.sku === productSku)
  }

  const removeCartItem = (product: ProductAttributes) => {
    const cartItemIndex = getCartItemIndex(product.sku)
    if (cartItemIndex >= 0) {
      const copiedCartItems = JSON.parse(JSON.stringify(cartItems))
      copiedCartItems.splice(cartItemIndex, 1)
      setCartItems([...copiedCartItems])
    }
  }

  const calculateTotalAmount = (item: CartAttributes) => {
    const discount = item.discount ? AmountHelpers().format(item.discount) : 0
    const tax = item.tax ? AmountHelpers().format(item.tax) : 0
    const tradeOfferDiscount = item.tradeOfferDiscount.discount
      ? AmountHelpers().format(parseFloat(item.tradeOfferDiscount.discount))
      : 0

    return (
      AmountHelpers().format(parseFloat(item.product.tpWoTax)) *
        calculateTotalQty({
          ctnQty: item.ctnQty,
          pcsQty: item.pcsQty,
          product: item.product
        }) -
      discount +
      tax -
      tradeOfferDiscount
    )
  }

  const addOrUpdateDiscountPriceAndTax = (
    productsWithDiscount: ProductWithDiscountAndTax,
    updatedItems?: CartAttributes[]
  ) => {
    const productsSku = Object.keys(productsWithDiscount)
    const copiedCartItems = JSON.parse(
      JSON.stringify(updatedItems ?? cartItems)
    )
    for (const sku of productsSku) {
      const cartItemIndex = getCartItemIndex(sku, updatedItems)

      copiedCartItems[cartItemIndex].discount =
        productsWithDiscount[sku].discount
      copiedCartItems[cartItemIndex].tax = productsWithDiscount[sku].tax
      copiedCartItems[cartItemIndex].netAmount = calculateTotalAmount(
        copiedCartItems[cartItemIndex]
      )
    }

    setCartItems(copiedCartItems)
  }

  const addOrUpdateSchemeLimits = (schemeLimits: SchemeLimit[]) => {
    setSchemeLimits(schemeLimits)
  }

  const getDiscountAndUpdateCart = (updatedItems?: CartAttributes[]) => {
    const productsWithDiscount: ProductWithDiscountAndTax =
      DiscountOfferModel().calculateTotal({
        cartItems: updatedItems ?? cartItems,
        schemeLimits
      })
    addOrUpdateDiscountPriceAndTax(productsWithDiscount, updatedItems)
  }
  const clear = () => {
    setCartItems([])
    setTotalCartAttributes({
      tradePrice: 0,
      gst: 0,
      discount: 0,
      tradeOffer: 0,
      totalAmount: 0
    })
  }

  return (
    <CartProvider
      value={{
        clear,
        cartItems,
        totalCartAttributes,
        schemeLimits,
        updateCartItem,
        removeCartItem,
        addOrUpdateDiscountPriceAndTax,
        addOrUpdateSchemeLimits,
        updateTotalCartAttributes
      }}
    >
      {children}
    </CartProvider>
  )
}

export default CartContainer
