import React from "react"
import { Grid, Typography, IconButton, TextField } from "@mui/material"
import AddIcon from "@mui/icons-material/AddCircle"
import SubtractIcon from "@mui/icons-material/RemoveCircle"

const styles = () => ({
  iconGrid: {
    textAlign: "center"
  }
})

const ProductQuantity = ({
  title,
  qty,
  handleQtyChange,
  location,
  qtyRef
}: {
  title: string
  qty: string
  location?: string
  handleQtyChange: Function
  qtyRef?: React.Ref<HTMLInputElement> | null
}) => {
  const classes = styles()

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if ((parseInt(value) >= 0 && parseInt(value) <= 999) || value === "") {
      if (value === "") handleQtyChange(0)
      else if (value[0] === "0") handleQtyChange(value.slice(1))
      else handleQtyChange(value)
    }
  }

  const getTypographyVariant = () => {
    if (location === "cart") return "caption"
    else "subtitle2"
  }

  return (
    <Grid container item xs={12} alignItems="center" justifyContent="center">
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant={getTypographyVariant()}>{title}:</Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={3} sx={classes.iconGrid}>
          <IconButton
            disabled={parseInt(qty) <= 0}
            onClick={() => handleQtyChange(parseInt(qty) - 1)}
          >
            <SubtractIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <TextField
            inputRef={qtyRef}
            name="qty"
            value={qty.toString()}
            fullWidth
            margin="none"
            size="small"
            variant="outlined"
            onChange={onTextChange}
            type="number"
          />
        </Grid>
        <Grid item xs={3} sx={classes.iconGrid}>
          <IconButton
            disabled={parseInt(qty) >= 999}
            onClick={() => handleQtyChange(parseInt(qty) + 1)}
          >
            <AddIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProductQuantity
