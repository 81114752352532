import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { ThemeMaker } from "./styles/ThemeMaker"
import Main from "./main/Main"

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <ThemeMaker>
          <Main />
        </ThemeMaker>
      </Router>
    </div>
  )
}

export default App
