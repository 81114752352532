import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import CategoryList from "../homepage/categories/categories.json"
import { Category } from "../homepage/categories/CategoryList"
import cpPhoenixGqlClient from "../../graphql/cp-phoenix"
import { ProductAttributes, PRODUCT_SIZE } from "../../model/Product"
import { LocalStorageKeys, getFromStorage } from "../../utils/LocalStorage"

interface sellCategory {
  sellCategory: string
  sellCategoryName: string
}
const useInfiniteScroll = (
  query: any,
  offset: number,
  searchKeywords?: string | null,
  sortOrder?: string | null
) => {
  const localStorageSession = getFromStorage(LocalStorageKeys.waSession)
  const [products, setProducts] = useState<ProductAttributes[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [getProducts, { data, error, loading }] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "network-only",
    client: cpPhoenixGqlClient
  })

  const getCategory = (categorySlug: string) => {
    return CategoryList.categories.find(
      (category: Category) => category.slug === categorySlug
    )?.id
  }

  useEffect(() => {
    setProducts([])
  }, [query?.category, searchKeywords, sortOrder])

  useEffect(() => {
    if (data?.products) {
      setHasMore(data.products.length > 0)
      setProducts([...products, ...data.products])
    } else if (data && !products.length) setProducts(data.products)
    else setHasMore(false)
  }, [data])

  const getSellCategories = () => {
    return localStorageSession?.waSession?.pop?.sellCategories.map(
      (category: sellCategory) => category.sellCategory
    )
  }

  useEffect(() => {
    getProducts({
      variables: {
        distributorId: localStorageSession?.waSession?.pop?.distributorId,
        sellCategory: getSellCategories(),
        popId: localStorageSession?.waSession?.pop?.popId,
        waCategory: getCategory(query?.category || ""),
        searchKeywords,
        sortOrder: !sortOrder ? "ASC" : sortOrder,
        offset,
        size: PRODUCT_SIZE
      }
    })
  }, [query, offset])

  return { loading, error, products, hasMore }
}

export default useInfiniteScroll

const PRODUCTS = gql`
  query products(
    $distributorId: String
    $sellCategory: [String]
    $searchKeywords: String
    $sortOrder: SortOrder
    $waCategory: String
    $popId: String
    $offset: Int
    $size: Int
  ) {
    products(
      distributorId: $distributorId
      sellCategory: $sellCategory
      searchKeywords: $searchKeywords
      waCategory: $waCategory
      sortOrder: $sortOrder
      popId: $popId
      offset: $offset
      size: $size
    ) {
      prod1
      product_group
      prod2
      principal
      prod3
      category
      waCategory
      prod4
      subCategory
      prod5
      brand
      prod6
      grammage
      masterSku
      masterSkuDesc
      sku
      skuDesc
      standardFactor
      batch
      sellUnit1
      sellWeight1
      sellFactor1
      sellUnit2
      sellWeight2
      sellFactor2
      sellUnit3
      sellWeight3
      sellFactor3
      prod7
      subBrand
      saleGroup
      saleGroupDesc
      prod8
      packSize
      prod9
      prod9Desc
      tpWoTax
      tax
      mrsp
      thirdSchedule
      image
      batchIndex
      sellCategory
    }
  }
`
