import React, { useContext } from "react"
import { Button, Grid, Typography } from "@mui/material"
import { useTheme, Theme } from "@mui/material/styles"
import { ShoppingCartOutlined } from "@mui/icons-material"
import CartContext from "../context/cart/CartContext"

// eslint-disable-next-line no-unused-vars
type Props = { handleClick(event: React.MouseEvent<HTMLElement>): void }

const styles = (theme: Theme) => ({
  button: {
    width: "auto",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    padding: "8px 16px",
    borderRadius: 50,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    }
  },
  icon: {
    width: 20,
    height: 20
  },
  typo: {
    padding: "0 8px 0 4px",
    fontWeight: "bold",
    textTransform: "lowercase"
  }
})

const HeaderButton: React.FC<Props> = ({ handleClick }) => {
  const classes = styles(useTheme())
  const cartContext = useContext(CartContext)

  return (
    <Button
      variant="contained"
      sx={classes.button}
      onClick={(e) => handleClick(e)}
    >
      <Grid container alignItems="center">
        <ShoppingCartOutlined />
        <Typography sx={classes.typo}>
          {cartContext?.cartItems.length ?? 0}
        </Typography>
      </Grid>
    </Button>
  )
}

export default HeaderButton
