import React from "react"
import { Grid, Typography } from "@mui/material"
import CheckoutCompleteImg from "../../assets/images/CheckoutComplete.svg"
import { useTheme, Theme } from "@mui/material/styles"

const styles = (theme: Theme) => ({
  main: {
    textAlign: "center",
    padding: 4,
    minHeight: "100vh"
  },
  image: {
    width: "100%",
    height: "auto",
    maxWidth: 400
  },
  text: {
    margin: " 12px auto",
    color: theme.palette.secondary.main
  }
})

const CheckoutCompletePage: React.FC = () => {
  const classes = styles(useTheme())
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={classes.main}
    >
      <Grid item xs={10}>
        <img
          style={classes.image}
          src={CheckoutCompleteImg}
          alt="validating_image"
        />
        <Typography sx={classes.text} variant="h4">
          Checkout Complete.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CheckoutCompletePage
