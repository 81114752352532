import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useLazyQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import cpPhoenixGqlClient from "../../graphql/cp-phoenix"
import { Grid, Typography, Card, LinearProgress, Button } from "@mui/material"
import { useTheme, Theme } from "@mui/material/styles"
import NoItemsInCart from "../../assets/images/NoItemsInCart.png"
import { CartContext } from "../context/cart/CartContext"
import { CartContextType } from "../../@types/cartTypes"
import CartItem from "./CartItem"
import Total from "./Total"
import { OrderProduct, ProductAttributes } from "../../model/Product"
import {
  getFromStorage,
  LocalStorageKeys,
  removeFromStorage
} from "../../utils/LocalStorage"
import ValidateCheckoutPage from "./ValidateCheckoutPage"
import { SchemeParams } from "../../model/offer/OfferModel"
import config from "../../config/config"

const styles = (theme: Theme) => ({
  card: {
    padding: 2,
    borderRadius: 2,
    margin: 2
  },
  productList: {
    backgroundColor: theme.bg.light,
    padding: 1
  },
  noItemsContainer: {
    textAlign: "center",
    height: "100%"
  },
  container: {
    minHeight: `calc(100vh - 56px)`,
    display: "flex",
    overflow: "auto",
    position: "fixed"
  },
  image: {
    width: 240,
    height: "auto"
  },
  text: {
    margin: "12px auto",
    fontSize: 20
  },
  cardGrid: {
    overflow: "scroll",
    maxHeight: "calc(100vh - 250px - 56px)"
  },
  buttonGrid: { padding: "0px 16px 0px 16px" },
  totalGrid: {
    position: "fixed",
    bottom: 0,
    height: 250
  },
  checkoutButton: { borderRadius: 0 }
})

const Checkout = () => {
  const classes = styles(useTheme())
  const cartContext = useContext(CartContext) as CartContextType
  const navigate = useNavigate()

  const [checkout, { data: checkoutData, loading: checkoutLoading }] =
    useMutation(CHECKOUT, {
      onError: () => navigate("/checkout/something-went-wrong")
    })

  const [getSchemeLimits, { data, loading }] = useLazyQuery(SCHEME_LIMITS, {
    fetchPolicy: "network-only",
    client: cpPhoenixGqlClient
  })

  useEffect(() => {
    if (cartContext?.cartItems) {
      const seqIdsAndMpCodes = getSeqIdsAndMpCodes()
      getSchemeLimits({ variables: { schemes: seqIdsAndMpCodes } })
    }
  }, [])

  useEffect(() => {
    if (data?.getSchemeLimits)
      cartContext?.addOrUpdateSchemeLimits(data.getSchemeLimits)
  }, [data])

  useEffect(() => {
    if (checkoutData?.checkout) {
      window.onbeforeunload = null
      cartContext.clear()
      removeFromStorage(LocalStorageKeys.waSession)
      navigate("/checkout/complete")
      window.location.href = `https://wa.me/${config.waba.id}`
    }
  }, [checkoutData])

  const handleCheckout = () => {
    const localStorageSession = getFromStorage(LocalStorageKeys.waSession)
    if (localStorageSession) {
      checkout({
        variables: {
          waSessionBvid: localStorageSession.waSession.bvid,
          tradePrice: cartContext.totalCartAttributes.tradePrice,
          gst: cartContext.totalCartAttributes.gst,
          discount: cartContext.totalCartAttributes.discount,
          tradeOffer: cartContext.totalCartAttributes.tradeOffer,
          totalAmount: cartContext.totalCartAttributes.totalAmount,
          products: getOrderProducts()
        }
      })
    }
  }

  const getOrderProducts = (): OrderProduct[] => {
    return cartContext?.cartItems.map((item) => {
      const product: ProductAttributes = {
        ...item.product
      }
      return {
        sku: product.sku,
        skuDesc: product.skuDesc,
        tpWoTax: product.tpWoTax,
        mrsp: product.mrsp,
        batch: product.batch,
        ctnSize: parseInt(product.sellFactor1),
        sellCategory: product.sellCategory,
        ctnQty: item.ctnQty,
        pcsQty: item.pcsQty,
        tradeOfferQty: parseInt(item.tradeOfferDiscount.freeUnits),
        tradeOfferValue: parseFloat(item.tradeOfferDiscount.discount),
        discount: item.discount ?? 0,
        gst: item.tax ?? 0,
        totalAmount: item.netAmount ?? 0
      }
    })
  }

  const getSeqIdsAndMpCodes = () => {
    const schemes: SchemeParams[] = []
    const seqIdAndMpCodes: string[] = []
    const cartItems = cartContext.cartItems

    cartItems.forEach((item) => {
      item.offers?.discountOffers.forEach((discountOffer) => {
        const seqIdAndMpCode = discountOffer.seqId + discountOffer.mpCode
        if (!seqIdAndMpCodes.includes(seqIdAndMpCode)) {
          seqIdAndMpCodes.push(seqIdAndMpCode)
          schemes.push({
            seqId: discountOffer.seqId,
            mpCode: discountOffer.mpCode
          })
        }
      })
    })

    return schemes
  }

  return !cartContext.cartItems.length ? (
    <Grid
      container
      sx={classes.container}
      justifyContent="center"
      alignItems="center"
    >
      <Card elevation={0} sx={classes.card}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={classes.noItemsContainer}
        >
          <Grid item>
            <img
              style={classes.image}
              src={NoItemsInCart}
              alt="no_items_image"
            />
            <Typography sx={classes.text} variant="h4">
              No items in cart!
            </Typography>
            <Typography variant="caption">
              Looks like you haven't added anything in the cart
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  ) : loading ? (
    <LinearProgress />
  ) : checkoutLoading ? (
    <ValidateCheckoutPage />
  ) : (
    <Grid container sx={classes.container} direction="column">
      <Grid item xs={12} sx={classes.cardGrid}>
        {cartContext.cartItems.map((item, index) => (
          <CartItem key={index} cartItem={item} />
        ))}
      </Grid>

      <Grid item xs={12} sx={classes.totalGrid}>
        <Total />
        <Button
          sx={classes.checkoutButton}
          variant="contained"
          size="large"
          fullWidth
          onClick={handleCheckout}
        >
          Checkout
        </Button>
      </Grid>
    </Grid>
  )
}

export default Checkout

const SCHEME_LIMITS = gql`
  query getSchemeLimits($schemes: [SchemeLimitInput]) {
    getSchemeLimits(schemes: $schemes) {
      mpCode
      seqId
      serial
      condition
      amountFrom
      amountTo
      quantityFrom
      quantityTo
    }
  }
`

const CHECKOUT = gql`
  mutation checkout(
    $waSessionBvid: ID!
    $tradePrice: Float
    $gst: Float
    $discount: Float
    $tradeOffer: Float
    $totalAmount: Float
    $products: [OrderProduct]
  ) {
    checkout(
      waSessionBvid: $waSessionBvid
      tradePrice: $tradePrice
      gst: $gst
      discount: $discount
      tradeOffer: $tradeOffer
      totalAmount: $totalAmount
      products: $products
    )
  }
`
