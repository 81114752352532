import { CartAttributes } from "../../@types/cartTypes"
import { getFromStorage, LocalStorageKeys } from "../../utils/LocalStorage"
import { SchemeLimit, TradeAndDiscountAttributes } from "../offer/OfferModel"
import { ProductAttributes } from "../Product"
import SchemeModel from "./SchemeModel"

const THIRD_SCHEDULE = "THIRD SCHEDULE"

export const DiscountOfferScheme = Object.freeze({
  DiscountPercent1: "001",
  DiscountPercent2: "016",
  FixedAmount: "008"
})

const TAX = Object.freeze({
  registered: 18,
  nonRegistered: 21
})
export interface GroupedDiscountOffer {
  [key: string]: TradeAndDiscountAttributes[]
}

export interface ProductWithDiscountAndTax {
  [sku: string]: { discount: number; tax: number }
}
export interface ProductWithQty {
  product: ProductAttributes
  totalQty: number
}
interface DiscountOfferResponse {
  discount: number
  remainingQty: number
  discountScheme: string | null
  discountPercent: number
}

const DiscountOfferModel = () => {
  const groupByMpCodeAndSeqId = ({
    discountOffers
  }: {
    discountOffers: TradeAndDiscountAttributes[] | undefined
  }): GroupedDiscountOffer => {
    const groupedDiscountOffer: GroupedDiscountOffer | null = {}
    if (discountOffers)
      for (const offer of discountOffers) {
        const key = offer.mpCode + offer.seqId

        if (groupedDiscountOffer?.[key]) {
          groupedDiscountOffer[key].push(offer)
        } else groupedDiscountOffer[key] = [offer]
      }
    return groupedDiscountOffer
  }

  const getGroupDiscountOfferKeys = (cartItems: CartAttributes[]) => {
    const keys = new Set<string>()
    cartItems.forEach((item) => {
      const groupedDiscountOfferKeys = Object.keys(item.groupedDiscountOffer)

      groupedDiscountOfferKeys.forEach((discountOfferKey) =>
        keys.add(discountOfferKey)
      )
    })
    return Array.from(keys.values())
  }

  const calculateTotalQty = ({
    ctnQty,
    pcsQty,
    product
  }: {
    ctnQty: number
    pcsQty: number
    product: ProductAttributes
  }) => {
    const ctnToPcs = ctnQty * parseInt(product.sellFactor1)
    return ctnToPcs + pcsQty
  }

  const getDiscountOffer = ({
    discountOffers,
    totalQty,
    totalTpWoTax
  }: {
    discountOffers: TradeAndDiscountAttributes[]
    totalQty: number
    totalTpWoTax: number
  }) => {
    const discountOffer = discountOffers.find(
      (discountOfferItem: TradeAndDiscountAttributes) =>
        (totalQty >= discountOfferItem.quantityFrom &&
          totalQty <= discountOfferItem.quantityTo) ||
        (totalTpWoTax >= discountOfferItem.amountFrom &&
          totalTpWoTax <= discountOfferItem.amountTo)
    )

    return discountOffer
  }

  const calculateTotalTpWoTaxAndProductCount = (
    productsWithQty: ProductWithQty[]
  ) => {
    let totalQty: number = 0
    let totalTpWoTax: number = 0

    productsWithQty.forEach((item) => {
      totalQty += item.totalQty
      totalTpWoTax += parseFloat(item.product.tpWoTax) * item.totalQty
    })

    return { totalQty, totalTpWoTax }
  }

  const applyDiscountScheme = ({
    discountOffer,
    totalQty,
    totalTpWoTax
  }: {
    discountOffer: TradeAndDiscountAttributes
    totalQty: number
    totalTpWoTax: number
  }): DiscountOfferResponse => {
    switch (discountOffer.mpCode) {
      case DiscountOfferScheme.DiscountPercent1: {
        const { discount, remainingQty } = calculateDiscountPercent({
          discountOffer,
          totalQty,
          totalTpWoTax
        })
        return {
          discount,
          remainingQty,
          discountScheme: DiscountOfferScheme.DiscountPercent1,
          discountPercent: discountOffer.discountValue
        }
      }
      case DiscountOfferScheme.DiscountPercent2: {
        const { discount, remainingQty } = calculateDiscountPercent({
          discountOffer,
          totalQty,
          totalTpWoTax
        })
        return {
          discount,
          remainingQty,
          discountScheme: DiscountOfferScheme.DiscountPercent1,
          discountPercent: discountOffer.discountValue
        }
      }
      case DiscountOfferScheme.FixedAmount: {
        return {
          discount: discountOffer.discountValue,
          remainingQty: 0,
          discountScheme: DiscountOfferScheme.FixedAmount,
          discountPercent: 0
        }
      }
      default:
        return {
          discount: 0,
          remainingQty: 0,
          discountScheme: "",
          discountPercent: 0
        }
    }
  }

  const calculateDiscountPercent = ({
    discountOffer,
    totalQty,
    totalTpWoTax
  }: {
    discountOffer: TradeAndDiscountAttributes
    totalQty: number
    totalTpWoTax: number
  }) => {
    if (totalQty < discountOffer.quantityFrom)
      return { discount: 0, remainingQty: 0 }
    const remainder = totalQty % discountOffer.dividedUnit
    const discount = totalTpWoTax * (discountOffer.discountValue / 100)

    return { discount, remainingQty: remainder }
  }

  const calculateForDiscountOffer = ({
    productsWithQty,
    discountOffers
  }: {
    productsWithQty: ProductWithQty[]
    discountOffers: TradeAndDiscountAttributes[]
  }) => {
    let totalDiscount: number = 0
    let discountScheme: string | null = null
    let discountPercent: number = 0
    const { totalQty, totalTpWoTax } =
      calculateTotalTpWoTaxAndProductCount(productsWithQty)
    let remainingQty: number = totalQty

    while (remainingQty) {
      const discountOffer = getDiscountOffer({
        discountOffers,
        totalQty: remainingQty,
        totalTpWoTax
      })

      const discount = discountOffer
        ? applyDiscountScheme({
            discountOffer,
            totalQty: remainingQty,
            totalTpWoTax
          })
        : {
            discount: 0,
            remainingQty: 0,
            discountScheme: null,
            discountPercent: 0
          }

      discountScheme = discount.discountScheme
      totalDiscount += discount.discount
      remainingQty = discount.remainingQty
      discountPercent = discount.discountPercent
    }
    return {
      totalDiscount,
      discountScheme,
      discountPercent
    }
  }

  const calculateTaxPerProduct = ({
    product,
    discount,
    qty
  }: {
    product: ProductAttributes
    discount: number
    qty: number
  }) => {
    const isRegisteredPop = () => {
      const localStorageSession = getFromStorage(LocalStorageKeys.waSession)
      if (localStorageSession) {
        const strn = localStorageSession.waSession.pop.strn
        return strn === "" ? false : true
      }
      return false
    }
    if (product.thirdSchedule === THIRD_SCHEDULE)
      return (
        ((parseFloat(product.mrsp) * TAX.registered) / (100 + TAX.registered)) *
        qty
      )

    const taxPercent = isRegisteredPop() ? TAX.registered : TAX.nonRegistered
    const totalPriceAfterDiscount = parseFloat(product.tpWoTax) * qty - discount
    return (totalPriceAfterDiscount * taxPercent) / 100
  }

  const calculateTax = ({
    cartItems,
    productsWithDiscountAndTax
  }: {
    cartItems: CartAttributes[]
    productsWithDiscountAndTax: ProductWithDiscountAndTax
  }) => {
    const productsWithDiscountAndTaxObj: ProductWithDiscountAndTax = JSON.parse(
      JSON.stringify(productsWithDiscountAndTax)
    )

    cartItems.forEach((item) => {
      const totalQty = calculateTotalQty({
        product: item.product,
        ctnQty: item.ctnQty,
        pcsQty: item.pcsQty
      })
      const sku = item.product.sku
      if (productsWithDiscountAndTaxObj[sku]) {
        const discount = productsWithDiscountAndTaxObj[sku].discount

        productsWithDiscountAndTaxObj[sku].tax = calculateTaxPerProduct({
          product: item.product,
          qty: totalQty,
          discount
        })
      }
    })
    return productsWithDiscountAndTaxObj
  }

  const calculateDiscountPerProduct = ({
    totalDiscount,
    productsWithQty,
    discountScheme,
    discountPercent,
    productsWithDiscountAndTax
  }: {
    totalDiscount: number
    productsWithQty: ProductWithQty[]
    productsWithDiscountAndTax: ProductWithDiscountAndTax
    discountScheme: string | null
    discountPercent: number
  }) => {
    const productsWithDiscountAndTaxObj: ProductWithDiscountAndTax = JSON.parse(
      JSON.stringify(productsWithDiscountAndTax)
    )
    let totalQty = 0
    const isDiscountPercent =
      discountScheme === DiscountOfferScheme.DiscountPercent1 ||
      discountScheme === DiscountOfferScheme.DiscountPercent2

    productsWithQty.forEach((product) => (totalQty += product.totalQty))
    productsWithQty.forEach((product) => {
      const sku = product.product.sku

      if (productsWithDiscountAndTaxObj[sku]) {
        isDiscountPercent
          ? (productsWithDiscountAndTaxObj[sku].discount =
              productsWithDiscountAndTaxObj[sku].discount +
              (parseFloat(product.product.tpWoTax) *
                discountPercent *
                product.totalQty) /
                100)
          : (productsWithDiscountAndTaxObj[sku].discount =
              productsWithDiscountAndTaxObj[sku].discount +
              (totalDiscount * product.totalQty) / totalQty)

        productsWithDiscountAndTaxObj[sku].tax = 0
      } else {
        productsWithDiscountAndTaxObj[sku] = isDiscountPercent
          ? {
              discount:
                (parseFloat(product.product.tpWoTax) *
                  discountPercent *
                  product.totalQty) /
                100,
              tax: 0
            }
          : {
              discount: (totalDiscount * product.totalQty) / totalQty,
              tax: 0
            }
      }
    })
    return productsWithDiscountAndTaxObj
  }

  const getSameDiscountOfferProducts = ({
    key,
    cartItems
  }: {
    key: string
    cartItems: CartAttributes[]
  }) => {
    const productsWithQty: ProductWithQty[] = []
    let discountOffers: TradeAndDiscountAttributes[] = []
    for (const item of cartItems) {
      if (item.groupedDiscountOffer[key]) {
        discountOffers = item.groupedDiscountOffer[key]
        const totalQty = calculateTotalQty({
          product: item.product,
          ctnQty: item.ctnQty,
          pcsQty: item.pcsQty
        })
        productsWithQty.push({ product: item.product, totalQty })
      }
    }
    return { productsWithQty, discountOffers }
  }

  const calculateTotal = ({
    cartItems,
    schemeLimits
  }: {
    cartItems: CartAttributes[]
    schemeLimits: SchemeLimit[]
  }) => {
    let productsWithDiscountAndTax: ProductWithDiscountAndTax = {}
    const discountOfferKeys = getGroupDiscountOfferKeys(cartItems)
    const groupedSchemeLimits = SchemeModel().groupByMpCodeAndSeqId({
      schemeLimits
    })

    for (const key of discountOfferKeys) {
      const productsWithSameDiscount = getSameDiscountOfferProducts({
        key,
        cartItems
      })

      if (groupedSchemeLimits[key]) {
        productsWithSameDiscount.productsWithQty =
          SchemeModel().getProductsForSchemeLimits({
            productsWithQty: productsWithSameDiscount.productsWithQty,
            schemeLimits: groupedSchemeLimits[key]
          })
      }

      const { totalDiscount, discountScheme, discountPercent } =
        calculateForDiscountOffer({
          productsWithQty: productsWithSameDiscount.productsWithQty,
          discountOffers: productsWithSameDiscount.discountOffers
        })

      productsWithDiscountAndTax = calculateDiscountPerProduct({
        totalDiscount: totalDiscount,
        productsWithQty: productsWithSameDiscount.productsWithQty,
        productsWithDiscountAndTax,
        discountScheme,
        discountPercent
      })
    }
    const discountProductSkus: string[] = Object.keys(
      productsWithDiscountAndTax
    )
    for (const item of cartItems) {
      if (!discountProductSkus.find((val) => val === item.product.sku))
        productsWithDiscountAndTax[item.product.sku] = { discount: 0, tax: 0 }
    }
    productsWithDiscountAndTax = calculateTax({
      cartItems,
      productsWithDiscountAndTax
    })

    return productsWithDiscountAndTax
  }

  return { groupByMpCodeAndSeqId, calculateTotal }
}

export default DiscountOfferModel
