import React, { useContext } from "react"
import { Snackbar, Slide, Alert } from "@mui/material"
import GlobalMessageContext from "../main/context/global_message/GlobalMessageContext"

const styles = () => ({
  alertContainer: {
    width: "100%",
    padding: "8px,16px"
  }
})

const CommonSnackbar: React.FC = () => {
  const classes = styles()
  const globalMessageContext = useContext(GlobalMessageContext)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      onClose={() =>
        globalMessageContext?.updateSnackBar({
          show: false,
          message: "",
          variant: "success"
        })
      }
      open={globalMessageContext?.snackBar.show}
      TransitionComponent={Slide}
      autoHideDuration={3000}
    >
      <Alert
        severity={globalMessageContext?.snackBar.variant}
        sx={classes.alertContainer}
      >
        {globalMessageContext?.snackBar.message}
      </Alert>
    </Snackbar>
  )
}
export default CommonSnackbar
