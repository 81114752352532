import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import LabelValue from "../../common/LabelValue"
import cpPhoenixGqlClient from "../../graphql/cp-phoenix"
import { LocalStorageKeys, getFromStorage } from "../../utils/LocalStorage"
import TradeOfferModel from "../../model/trade_offer/TradeOfferModel"
import { TradeAndDiscountAttributes } from "../../model/offer/OfferModel"
import { OfferType } from "../../@types/offerTypes"

const Offer: React.FC<OfferType> = ({
  sku,
  totalPcsQty,
  handleOffers,
  handleLoading
}) => {
  const [tradeOffer, setTradeOffer] =
    useState<TradeAndDiscountAttributes | null>(null)

  const [tradeOfferMsg, setTradeOfferMsg] = useState("")
  const localStorageSession = getFromStorage(LocalStorageKeys.waSession)
  const { data, loading } = useQuery(TRADE_OFFERS, {
    variables: {
      sku,
      popId: localStorageSession?.waSession?.pop?.popId
    },
    fetchPolicy: "network-only",
    client: cpPhoenixGqlClient,
    onError: () => handleLoading(false)
  })

  useEffect(() => {
    if (data?.getOffers?.tradeOffers) {
      handleOffers(data.getOffers)
      setTradeOffer(
        TradeOfferModel().getSpecificTradeOffer({
          tradeOffers: data.getOffers.tradeOffers,
          totalPcsQty
        })
      )
    }
    if (!loading) handleLoading(false)
  }, [data])

  useEffect(() => {
    if (tradeOffer) setTradeOfferMsg(TradeOfferModel().createString(tradeOffer))
    else setTradeOfferMsg("NIL")
  }, [tradeOffer])

  useEffect(() => {
    if (typeof totalPcsQty === "number" && data?.getOffers)
      setTradeOffer(
        TradeOfferModel().getSpecificTradeOffer({
          tradeOffers: data.getOffers.tradeOffers,
          totalPcsQty
        })
      )
  }, [totalPcsQty])

  return (
    <LabelValue title="Trade Offer" value={tradeOfferMsg} loading={loading} />
  )
}

export default Offer

const TRADE_OFFERS = gql`
  query getOffers($popId: String, $sku: String) {
    getOffers(popId: $popId, sku: $sku) {
      discountOffers {
        mpCode
        seqId
        serialNo
        quantityFrom
        quantityTo
        discountQuantity
        discountValue
        brandProduct
        dividedUnit
        purchaseLimit
        schemeType
        fieldComb
        valueComb
        amountFrom
        amountTo
      }
      tradeOffers {
        mpCode
        seqId
        serialNo
        quantityFrom
        quantityTo
        discountQuantity
        discountValue
        brandProduct
        dividedUnit
        purchaseLimit
        schemeType
        fieldComb
        valueComb
        amountFrom
        amountTo
      }
    }
  }
`
