import React, { useState } from "react"
import Routes from "./layout/Router"
import { Grid } from "@mui/material"
import AppBar from "./layout/AppBar"
import WaSession from "./homepage/waSession/WaSession"
import CartContainer from "./context/cart/CartContainer"
import GlobalMessageContainer from "./context/global_message/GlobalMessageContainer"
import CommonSnackbar from "../common/CommonSnackbar"

const Main: React.FC = () => {
  const [validate, setValidate] = useState(false)

  const handleValidate = (value: boolean) => {
    setValidate(value)
  }

  return (
    <Grid>
      {validate ? (
        <GlobalMessageContainer>
          <CartContainer>
            <AppBar />
            <Routes />
            <CommonSnackbar />
          </CartContainer>
        </GlobalMessageContainer>
      ) : (
        <WaSession handleValidate={handleValidate} />
      )}
    </Grid>
  )
}

export default Main
