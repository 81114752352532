import React, { useEffect } from "react"
import { Grid, Typography, Button } from "@mui/material"
import NotValidateWhatsappImg from "../../../assets/images/NotValidated.svg"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import { useTheme, Theme } from "@mui/material/styles"
import CartContext from "../../context/cart/CartContext"
import config from "../../../config/config"

const styles = (theme: Theme) => ({
  main: {
    textAlign: "center",
    padding: 4,
    minHeight: "100vh"
  },
  image: {
    width: "100%",
    height: "auto",
    maxWidth: 400
  },
  text: {
    margin: "12px auto",
    color: theme.palette.secondary.main
  },
  whatsApp: {
    color: "#25D366",
    border: {
      borderColor: "#25D366"
    }
  }
})

const InvalidSessionPage: React.FC = () => {
  const classes = styles(useTheme())
  const cartContext = React.useContext(CartContext)

  useEffect(() => {
    cartContext?.clear()
  }, [])
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={classes.main}
    >
      <Grid item xs={10}>
        <img
          style={classes.image}
          src={NotValidateWhatsappImg}
          alt="invalid_session_image"
        />
        <Typography sx={classes.text} variant="h4">
          Your session is expired or invalid.
        </Typography>
        <Typography sx={classes.text} variant="h6">
          Please try again.
        </Typography>
        <Button
          variant="outlined"
          startIcon={<WhatsAppIcon />}
          href={`https://wa.me/${config.waba.id}`}
          sx={classes.whatsApp}
        >
          WhatsApp
        </Button>
      </Grid>
    </Grid>
  )
}

export default InvalidSessionPage
