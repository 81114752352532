import React from "react"
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Category } from "./CategoryList"

const styles = () => ({
  categoryGrid: {
    padding: 1
  },
  cardContent: {
    padding: 1,
    justifyContent: "space-between"
  },
  name: {
    padding: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontWeight: "bold",
    height: 40,
    fontSize: 16
  },
  card: {
    borderRadius: 2
  },
  imageContainer: {
    height: 100,
    textAlign: "center",
    justifyContent: "center"
  },
  image: {
    width: "100%",
    height: "auto"
  }
})

const CategoryCard = ({ category }: { category: Category }) => {
  const classes = styles()
  const navigate = useNavigate()

  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      lg={2}
      sx={classes.categoryGrid}
      onClick={() => {
        navigate(`/category/${category.slug}`)
      }}
    >
      <Card sx={classes.card} elevation={0}>
        <CardActionArea>
          <CardContent sx={classes.cardContent}>
            <Grid container flexDirection="column" alignItems="center">
              <Grid
                container
                direction="column"
                item
                sx={classes.imageContainer}
                xs={12}
              >
                <img
                  src={category.imgUrl}
                  style={classes.image}
                  alt={category.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" sx={classes.name}>
                  {category.name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default CategoryCard
