import { SchemeLimit } from "../offer/OfferModel"
import { ProductWithQty } from "./DiscountOfferModel"

export interface GroupedSchemeLimit {
  [key: string]: SchemeLimit[]
}

const SchemeModel = () => {
  const groupByMpCodeAndSeqId = ({
    schemeLimits
  }: {
    schemeLimits: SchemeLimit[] | undefined
  }): GroupedSchemeLimit => {
    const groupedDiscountScheme: GroupedSchemeLimit | null = {}
    if (schemeLimits)
      for (const offer of schemeLimits) {
        const key = offer.mpCode + offer.seqId

        if (groupedDiscountScheme?.[key]) {
          groupedDiscountScheme[key].push(offer)
        } else groupedDiscountScheme[key] = [offer]
      }
    return groupedDiscountScheme
  }
  const getSchemeCondition = (scheme: SchemeLimit) => {
    const skuList: string[] = []
    let prods: string | string[] | null = null
    let prodValues: string | string[] | null = null
    let condition: string | string[] = scheme.condition

    condition = condition.replace(/[)([\]'`~]/g, "")

    if (condition.includes("SKU")) {
      condition = condition.split("OR")
      condition.forEach((sku) => {
        skuList.push(sku.split("=")[1].trim())
      })
    } else {
      condition = condition.split("=")
      prods = condition[0].split("++")
      prodValues = condition[1].split("~")
      prods = prods[prods.length - 1].trim()
      prodValues = prodValues[prodValues.length - 1].trim()
    }
    return {
      prod: prods,
      prodValue: prodValues,
      skuList
    }
  }

  const filterByProdCondition = ({
    prodValue,
    productsWithQty,
    skuList
  }: {
    prodValue: string | null
    productsWithQty: ProductWithQty[]
    skuList: string[]
  }) => {
    return productsWithQty.filter((item) => {
      const prodKey = (
        Object.keys(item.product) as (keyof typeof item.product)[]
      ).find((key) => {
        return item.product[key] === prodValue
      })

      if (prodKey && item.product[prodKey] === prodValue) return true
      else if (skuList.includes(item.product.sku)) return true
    })
  }
  const getProductsForSchemeLimits = ({
    schemeLimits,
    productsWithQty
  }: {
    productsWithQty: ProductWithQty[]
    schemeLimits: SchemeLimit[]
  }) => {
    let filteredProducts: ProductWithQty[] = []

    const res = schemeLimits.every((schemeLimit) => {
      const { prodValue, skuList } = getSchemeCondition(schemeLimit)

      filteredProducts = filterByProdCondition({
        prodValue,
        productsWithQty,
        skuList
      })

      if (filteredProducts.length) {
        let totalQty = 0
        totalQty = productsWithQty.reduce(
          (accumulator, curValue) => accumulator + curValue.totalQty,
          totalQty
        )

        if (
          totalQty >= schemeLimit.quantityFrom &&
          totalQty <= schemeLimit.quantityTo
        )
          return true
      }
    })
    return res ? filteredProducts : []
  }

  return { getProductsForSchemeLimits, groupByMpCodeAndSeqId }
}

export default SchemeModel
