import React, { useState, useRef, useCallback, useEffect } from "react"
import ProductItem from "./ProductItem"
import { Grid, Typography } from "@mui/material"
import { useTheme, Theme } from "@mui/material/styles"
import { useParams, useLocation } from "react-router-dom"
import ProductNotFound from "./ProductNotFound"
import useInfiniteScroll from "./InfiniteScroll"
import ProductSkeleton from "./ProductSkeleton"
import SearchBar from "../search/searchBar"
import CategoryList from "../homepage/categories/categories.json"
import { Category } from "../homepage/categories/CategoryList"
import { ProductAttributes, PRODUCT_SIZE } from "../../model/Product"

const styles = (theme: Theme) => ({
  productList: {
    backgroundColor: theme.bg.light,
    padding: "0px 8px 8px 8px"
  },
  title: {
    padding: 2,
    fontWeight: "bold"
  }
})

const ProductList = () => {
  const search = useLocation().search
  const searchKeywords = new URLSearchParams(search).get("keywords")
  const sortOrder = new URLSearchParams(search).get("sort")
  const classes = styles(useTheme())
  const query = useParams()
  const [offset, setOffset] = useState(0)
  const { products, hasMore, loading } = useInfiniteScroll(
    query,
    offset,
    searchKeywords,
    sortOrder
  )

  useEffect(() => {
    setOffset(0)
  }, [searchKeywords])

  const observer: any = useRef()

  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((prevOffset) => prevOffset + PRODUCT_SIZE)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  const getCategoryName = (categorySlug: string) => {
    return CategoryList.categories.find(
      (category: Category) => category.slug === categorySlug
    )?.name
  }
  return (
    <Grid>
      <Typography variant="h6" sx={classes.title}>
        {getCategoryName(query?.category || "")}
      </Typography>

      <SearchBar clearOffset={setOffset} />

      <Grid container sx={classes.productList}>
        {products?.length ? (
          products.map((product: ProductAttributes, index: number) => (
            <ProductItem
              key={product.sku + index}
              product={product}
              forRef={
                products.length === index + 1 ? lastProductElementRef : null
              }
            />
          ))
        ) : (
          <></>
        )}

        {loading ? <ProductSkeleton /> : <></>}
      </Grid>
      <Grid>{!loading && products === null ? <ProductNotFound /> : <></>}</Grid>
    </Grid>
  )
}

export default ProductList
