import { TradeAndDiscountAttributes } from "../offer/OfferModel"
import { ProductAttributes } from "../../model/Product"

interface TradeOfferDiscountResponse {
  amount: number
  remainingQty: number
  freeUnits?: number
}
export const TradeOfferDiscountScheme = Object.freeze({
  DiscountValue: "006",
  DiscountPercent: "016",
  DiscountQty: "007"
})

const TradeOfferModel = () => {
  const createString = (tradeOffer: TradeAndDiscountAttributes) => {
    switch (tradeOffer.mpCode) {
      case TradeOfferDiscountScheme.DiscountValue:
        return `Buy ${tradeOffer.quantityFrom} pc(s) save Rs ${tradeOffer.discountValue} per pc`
      case TradeOfferDiscountScheme.DiscountPercent:
        return `Buy ${tradeOffer.quantityFrom} pc(s) get ${tradeOffer.discountValue}% off`
      case TradeOfferDiscountScheme.DiscountQty:
        return `Buy ${tradeOffer.quantityFrom} pc(s) get ${tradeOffer.discountQuantity} pc(s) free`
      default:
        return "NIL"
    }
  }

  const getSpecificTradeOffer = ({
    tradeOffers,
    totalPcsQty
  }: {
    tradeOffers: TradeAndDiscountAttributes[]
    totalPcsQty: number
  }) => {
    let tradeOffer = tradeOffers.find(
      (tradeOfferItem: TradeAndDiscountAttributes) =>
        tradeOfferItem.serialNo === "1"
    )

    if (
      !(tradeOffer && (!totalPcsQty || totalPcsQty < tradeOffer.quantityFrom))
    )
      tradeOffer = tradeOffers.find(
        (tradeOfferItem: TradeAndDiscountAttributes) =>
          totalPcsQty >= tradeOfferItem.quantityFrom &&
          totalPcsQty <= tradeOfferItem.quantityTo
      )

    if (tradeOffer?.discountQuantity === 0 && tradeOffer?.discountValue === 0)
      tradeOffer = tradeOffers.find(
        (tradeOfferItem: TradeAndDiscountAttributes) =>
          tradeOfferItem.serialNo ===
          `${parseInt(tradeOffer?.serialNo ?? "0") + 1}`
      )

    return tradeOffer || null
  }

  const calculate = ({
    tradeOffers,
    totalQty,
    product
  }: {
    tradeOffers: TradeAndDiscountAttributes[]
    totalQty: number
    product: ProductAttributes
  }) => {
    let totalNetAmount: number = 0
    let remainingQty: number = totalQty
    let freeUnits: number = 0
    while (remainingQty) {
      const tradeOffer = getTradeOffer({ tradeOffers, totalQty: remainingQty })

      const discount: TradeOfferDiscountResponse = tradeOffer
        ? applyDiscountScheme({
            tradeOffer,
            totalQty: remainingQty,
            product
          })
        : { amount: 0, remainingQty: 0 }

      totalNetAmount += discount.amount
      freeUnits += discount?.freeUnits ?? 0
      remainingQty = discount.remainingQty
    }
    return {
      discount: totalNetAmount.toFixed(2),
      freeUnits: `${freeUnits} free units`
    }
  }

  const getTradeOffer = ({
    tradeOffers,
    totalQty
  }: {
    tradeOffers: TradeAndDiscountAttributes[]
    totalQty: number
  }) => {
    let tradeOffer = tradeOffers.find(
      (tradeOfferItem: TradeAndDiscountAttributes) =>
        totalQty >= tradeOfferItem.quantityFrom &&
        totalQty <= tradeOfferItem.quantityTo
    )
    if (!tradeOffer)
      tradeOffer = tradeOffers.find(
        (tradeOfferItem: TradeAndDiscountAttributes) =>
          tradeOfferItem.serialNo === "1"
      )

    return tradeOffer
  }

  const applyDiscountScheme = ({
    tradeOffer,
    totalQty,
    product
  }: {
    tradeOffer: TradeAndDiscountAttributes
    totalQty: number
    product: ProductAttributes
  }): TradeOfferDiscountResponse => {
    switch (tradeOffer.mpCode) {
      case TradeOfferDiscountScheme.DiscountValue:
        return calculateDiscountValue({ tradeOffer, totalQty })
      case TradeOfferDiscountScheme.DiscountPercent:
        return calculateDiscountPercent({ tradeOffer, totalQty, product })
      case TradeOfferDiscountScheme.DiscountQty:
        return calculateDiscountQty({ tradeOffer, totalQty })
      default:
        return { amount: 0, remainingQty: 0 }
    }
  }

  const calculateDiscountQty = ({
    tradeOffer,
    totalQty
  }: {
    tradeOffer: TradeAndDiscountAttributes
    totalQty: number
  }): TradeOfferDiscountResponse => {
    if (totalQty < tradeOffer.quantityFrom)
      return { amount: 0, remainingQty: 0 }

    const remainder = totalQty % tradeOffer.dividedUnit
    const pcsForDiscount = totalQty - remainder
    const freeUnits = tradeOffer.discountQuantity
      ? (pcsForDiscount / tradeOffer.dividedUnit) * tradeOffer.discountQuantity
      : 0

    return { amount: 0, remainingQty: remainder, freeUnits }
  }

  const calculateDiscountPercent = ({
    tradeOffer,
    totalQty,
    product
  }: {
    tradeOffer: TradeAndDiscountAttributes
    totalQty: number
    product: ProductAttributes
  }): TradeOfferDiscountResponse => {
    if (totalQty < tradeOffer.quantityFrom)
      return { amount: 0, remainingQty: 0 }

    const remainder = totalQty % tradeOffer.dividedUnit
    const pcsForDiscount = totalQty - remainder
    const discount =
      pcsForDiscount *
      parseFloat(product.tpWoTax) *
      (tradeOffer.discountValue / 100)
    return { amount: discount, remainingQty: remainder }
  }

  const calculateDiscountValue = ({
    tradeOffer,
    totalQty
  }: {
    tradeOffer: TradeAndDiscountAttributes
    totalQty: number
  }) => {
    if (totalQty < tradeOffer.quantityFrom)
      return { amount: 0, remainingQty: 0 }

    const remainder = totalQty % tradeOffer.dividedUnit
    const pcsForDiscount = totalQty - remainder
    const discount =
      (pcsForDiscount / tradeOffer.dividedUnit) * tradeOffer.discountValue

    return { amount: discount, remainingQty: remainder }
  }

  return { createString, getSpecificTradeOffer, calculate }
}

export default TradeOfferModel
