import { createTheme } from "@mui/material/styles"
import customBlack from "./color/customBlack"
import customOrange from "./color/customOrange"
import { grey } from "@mui/material/colors"

export const MobileView = "(max-width:1024px)"
export const createMaterialTheme = (color = customOrange[500]) => {
  return createTheme({
    palette: {
      primary: {
        main: "#a30000",
        light: "#fff",
        dark: color,
        contrastText: "#fff"
      },
      secondary: {
        main: customBlack[500],
        light: grey[600]
      }
    },
    typography: {
      fontFamily: "'Poppins'",
      allVariants: {
        color: customBlack[500],
        fontWeight: 500
      },
      caption: {
        color: "#969696",
        fontSize: 12
      },
      h4: {
        fontWeight: "bold"
      },
      h6: {
        fontWeight: 500
      },
      subtitle2: {
        fontSize: 15,
        fontWeight: 500
      }
    },
    bg: {
      main: "#fff",
      light: "#F4F5F7"
    }
  })
}
