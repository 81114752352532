const customOrange = {
  50: "#fef3e3",
  100: "#fde0ba",
  200: "#fccb8e",
  300: "#fab764",
  400: "#f9a74a",
  500: "#f79a3c",
  600: "#f28f39",
  700: "#ea8036",
  800: "#e27233",
  900: "#d85b2c",
  A100: "#ffd180",
  A200: "#ffab40",
  A400: "#ff9100",
  A700: "#ff6d00"
}

export default customOrange
