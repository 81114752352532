import React, { useState, useEffect, useContext } from "react"
import { Button, Grid, Typography } from "@mui/material"
import ProductQuantity from "../../common/ProductQuantity"
import LabelValue from "../../common/LabelValue"
import { useTheme, Theme } from "@mui/material/styles"
import { ProductAttributes } from "../../model/Product"
import Offer from "./Offer"
import { CartContext } from "../context/cart/CartContext"
import { GlobalMessageContext } from "../context/global_message/GlobalMessageContext"
import { CartContextType } from "../../@types/cartTypes"
import ImgNotFound from "../../assets/images/imagenotfound.png"
import { OfferAttributes } from "../../model/offer/OfferModel"
import DiscountOfferModel, {
  GroupedDiscountOffer
} from "../../model/discount_offer/DiscountOfferModel"
import TradeOfferModel from "../../model/trade_offer/TradeOfferModel"
import AmountHelpers from "../../utils/AmountHelpers"
import { GlobalMessageContextType } from "../../@types/GlobalMessageContext"

const styles = (theme: Theme) => ({
  container: {
    padding: 1,
    textAlign: "center"
  },
  productGrid: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    padding: 1
  },
  header: {
    padding: 1
  },
  img: {
    objectFit: "contain",
    borderRadius: 8,
    height: 60
  } as React.CSSProperties,
  name: {
    paddingLeft: 1
  },
  detail: {
    padding: 1
  },
  typography: {
    fontSize: 16,
    fontWeight: 600
  }
})

const ProductItem = ({
  product,
  forRef
}: {
  product: ProductAttributes
  forRef?: React.Ref<HTMLDivElement> | null
}) => {
  const cartContext = useContext(CartContext) as CartContextType
  const globalMessageContext = useContext(
    GlobalMessageContext
  ) as GlobalMessageContextType
  const classes = styles(useTheme())

  const getCartItemByProduct = (product: ProductAttributes) => {
    return cartContext?.cartItems.find(
      (cartItem) => product.sku === cartItem.product.sku
    )
  }

  const [ctnQty, setCtnQty] = useState<string>(
    getCartItemByProduct(product)?.ctnQty.toString() || "0"
  )
  const [pcsQty, setPcsQty] = useState<string>(
    getCartItemByProduct(product)?.pcsQty.toString() || "0"
  )
  const [netAmount, setNetAmount] = useState(0)
  const [offers, setOffers] = useState<OfferAttributes | null>(null)
  const [loading, setLoading] = useState(true)

  const calculateTotalQty = () => {
    const ctnToPcs = parseInt(ctnQty) * parseInt(product.sellFactor1)
    return ctnToPcs + parseInt(pcsQty)
  }

  const handleCtnQtyChange = (newQty: string) => {
    setCtnQty(newQty)
  }

  const handlePcsQtyChange = (newQty: string) => {
    setPcsQty(newQty)
  }

  const handleOffers = (selectedTradeOffers: OfferAttributes) => {
    setOffers(selectedTradeOffers)
  }

  const getTradeOfferDiscount = () => {
    return offers?.tradeOffers
      ? TradeOfferModel().calculate({
          tradeOffers: offers.tradeOffers,
          totalQty: calculateTotalQty(),
          product
        })
      : { discount: "0", freeUnits: "0 free units" }
  }

  const handleAddToCart = () => {
    const groupedDiscountOffer: GroupedDiscountOffer =
      DiscountOfferModel().groupByMpCodeAndSeqId({
        discountOffers: offers?.discountOffers
      })
    cartContext.updateCartItem({
      netAmount,
      product,
      pcsQty: parseInt(pcsQty),
      ctnQty: parseInt(ctnQty),
      offers,
      groupedDiscountOffer,
      tradeOfferDiscount: getTradeOfferDiscount()
    })
    const totalQty = calculateTotalQty()
    const existingQty =
      (getCartItemByProduct(product)?.ctnQty || 0) *
        parseInt(product.sellFactor1) +
      (getCartItemByProduct(product)?.pcsQty || 0)

    const removeOrAdd = totalQty > existingQty ? "added to" : "removed from"
    globalMessageContext.updateSnackBar({
      show: true,
      message: `${Math.abs(
        totalQty - existingQty
      )} item(s) ${removeOrAdd} cart`,
      variant: "success"
    })
  }

  const calculateNetAmount = ({
    totalQty,
    product
  }: {
    totalQty: number
    product: ProductAttributes
  }) =>
    parseFloat(
      (
        totalQty *
        (parseFloat(product.tpWoTax) + parseFloat(product.tax))
      ).toFixed(2)
    )

  const handleLoading = (value: boolean) => {
    setLoading(value)
  }

  useEffect(() => {
    if (ctnQty || pcsQty) {
      setNetAmount(
        calculateNetAmount({ totalQty: calculateTotalQty(), product })
      )
    } else if (!(ctnQty && pcsQty)) setNetAmount(0)
  }, [ctnQty, pcsQty])

  const isButtonDisable = () => {
    const item = getCartItemByProduct(product)
    return (
      !calculateTotalQty() ||
      loading ||
      (parseInt(ctnQty) === item?.ctnQty && parseInt(pcsQty) === item?.pcsQty)
    )
  }

  return (
    <Grid
      ref={forRef}
      container
      item
      xs={12}
      sm={6}
      lg={4}
      sx={classes.container}
    >
      <Grid container item sx={classes.productGrid}>
        <Grid
          container
          item
          sx={classes.header}
          alignItems="flex-start"
          wrap="nowrap"
        >
          <Grid item xs={3}>
            <img
              width="100%"
              style={classes.img}
              src={product.image ? product.image : ImgNotFound}
              alt="prod_img"
            />
          </Grid>
          <Grid item xs={10} sx={classes.name}>
            <Typography sx={classes.typography}>{product.skuDesc}</Typography>
          </Grid>
        </Grid>

        <Grid container item xs={6}>
          <ProductQuantity
            title="Ctn"
            qty={ctnQty}
            handleQtyChange={handleCtnQtyChange}
          />
        </Grid>
        <Grid container item xs={6}>
          <ProductQuantity
            title="Pcs"
            qty={pcsQty}
            handleQtyChange={handlePcsQtyChange}
          />
        </Grid>

        <Grid container item xs={12}>
          <Grid container item xs={6} md={3} sx={classes.detail}>
            <LabelValue
              title="Retail Price"
              value={`Rs. ${AmountHelpers().formatToCurrency(
                parseFloat(product.mrsp)
              )}`}
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={3}
            sx={classes.detail}
            justifyContent="center"
          >
            <Offer
              sku={product.sku}
              totalPcsQty={calculateTotalQty()}
              handleOffers={handleOffers}
              handleLoading={handleLoading}
            />
          </Grid>
          <Grid container item xs={6} md={3} sx={classes.detail}>
            <LabelValue
              title="Trade Price"
              value={`Rs. ${AmountHelpers().formatToCurrency(
                parseFloat(product.tpWoTax)
              )}`}
            />
          </Grid>
          <Grid container item xs={6} md={3} sx={classes.detail}>
            <LabelValue
              title="Net Amount"
              value={`Rs. ${AmountHelpers().formatToCurrency(netAmount)}`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            fullWidth
            disabled={isButtonDisable()}
            onClick={handleAddToCart}
          >
            Add to cart
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProductItem
