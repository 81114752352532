import React, { useState, useEffect } from "react"
import { Grid, TextField, IconButton, InputAdornment } from "@mui/material"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import useDebounce from "./Debounce"
import { useTheme, Theme } from "@mui/material/styles"
import SearchIcon from "@mui/icons-material/Search"
import AscIcon from "../../assets/icons/sortAsc.svg"
import DescIcon from "../../assets/icons/sortDesc.svg"

const styles = (theme: Theme) => ({
  searchContainer: { padding: "0 16px 0 16px" },
  icon: {
    height: 30
  },
  textField: { backgroundColor: theme.bg.main }
})

const SearchBar = ({ clearOffset }: { clearOffset?: Function }) => {
  const search = useLocation().search
  const pathName = useLocation().pathname
  const sort = new URLSearchParams(search).get("sort")
  const keywords = new URLSearchParams(search).get("keywords")
  const params = useParams()
  const [searchKeywords, setSearchKeywords] = useState<string>(keywords || "")
  const debouncedValue = useDebounce<string>(searchKeywords, 500)
  const classes = styles(useTheme())
  const navigate = useNavigate()

  useEffect(() => {
    if (searchKeywords?.length >= 3 || searchKeywords?.length === 0) {
      navigate(`${getCategory()}/search?keywords=${searchKeywords}`)
    }
  }, [debouncedValue])

  const handleTextChange = (e: any) => {
    setSearchKeywords(e.target.value)
  }

  const searchProducts = () => {
    navigate(`${getCategory()}/search?keywords=${searchKeywords}`)
  }

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault()
      clearOffset?.(0)
      searchProducts()
    }
  }

  const getKeywordQuery = () => {
    if (keywords) return `keywords=${keywords}&`
    return ""
  }

  const getCategory = () => {
    if (params?.category) return `/category/${params.category}`
    if (pathName)
      return getPathName(pathName) === "" ? "" : `/${getPathName(pathName)}`
    return ""
  }

  const getPathName = (pathName: string) => {
    const pathNameArr = pathName.split("/")
    return pathNameArr.length > 0 ? `${pathNameArr[1]}` : ""
  }

  const handleSort = () => {
    clearOffset?.(0)
    if (!sort) navigate(`${getCategory()}/search?${getKeywordQuery()}sort=DESC`)
    else if (sort === "ASC")
      navigate(`${getCategory()}/search?${getKeywordQuery()}sort=DESC`)
    else if (sort === "DESC")
      navigate(`${getCategory()}/search?${getKeywordQuery()}sort=ASC`)
  }

  return (
    <Grid container sx={classes.searchContainer} wrap="nowrap">
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          id="outlined-basic"
          size="small"
          fullWidth
          sx={classes.textField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onKeyPress={handleKeyPress}
          value={searchKeywords}
          onChange={handleTextChange}
        />
      </Grid>
      <Grid item>
        <IconButton color="primary" onClick={handleSort}>
          {!sort || sort === "ASC" ? (
            <img style={classes.icon} src={AscIcon} alt="AscIcon" />
          ) : (
            <img style={classes.icon} src={DescIcon} alt="DescIcon" />
          )}
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default SearchBar
