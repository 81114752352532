import React from "react"
import { Grid, Typography, Button } from "@mui/material"
import NotValidateWhatsappImg from "../../assets/images/NotValidated.svg"
import CategoryIcon from "@mui/icons-material/Category"
import { useTheme, Theme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"

const styles = (theme: Theme) => ({
  main: {
    textAlign: "center",
    padding: 1,
    minHeight: "100%"
  },
  image: {
    width: "100%",
    height: "auto",
    maxWidth: 400
  },
  text: {
    margin: " 12px auto",
    color: theme.palette.secondary.main
  }
})

const ProductNotFound: React.FC = () => {
  const navigate = useNavigate()
  const classes = styles(useTheme())
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={classes.main}
    >
      <Grid item xs={10}>
        <img
          style={classes.image}
          src={NotValidateWhatsappImg}
          alt="no_products_image"
        />
        <Typography sx={classes.text} variant="h5">
          No products found in this category.
        </Typography>
        <Button
          variant="outlined"
          startIcon={<CategoryIcon />}
          onClick={() => {
            navigate(`/`)
          }}
        >
          Categories
        </Button>
      </Grid>
    </Grid>
  )
}

export default ProductNotFound
