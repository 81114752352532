import React from "react"
import { Grid } from "@mui/material"
import { useTheme, Theme } from "@mui/material/styles"
import Skeleton from "@mui/material/Skeleton"
const styles = (theme: Theme) => ({
  container: {
    padding: 1
  },
  productGrid: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    padding: 1
  }
})
const ProductSkeleton = () => {
  const classes = styles(useTheme())

  return (
    <>
      {[...Array(6).keys()].map((item) => (
        <Grid
          key={item}
          container
          item
          xs={12}
          sm={6}
          lg={4}
          sx={classes.container}
        >
          <Grid container item xs={12} sx={classes.productGrid}>
            <Grid item xs={3}>
              <Skeleton variant="rectangular" width={50} height={40} />
            </Grid>
            <Grid item xs={9}>
              <Skeleton width="100%" />
            </Grid>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default ProductSkeleton
