import React, { useState, useEffect, useContext, useRef } from "react"
import { Divider, Grid, Typography } from "@mui/material"
import { useTheme, Theme } from "@mui/material/styles"
import {
  CartAttributes,
  CartContextType,
  TradeOfferDiscount
} from "../../@types/cartTypes"
import ImgNotFound from "../../assets/images/imagenotfound.png"
import LabelValue from "../../common/LabelValue"
import ProductQuantity from "../../common/ProductQuantity"
import { calculateTotalQty } from "../../model/modelHelpers"
import CartContext from "../context/cart/CartContext"
import TradeOfferModel from "../../model/trade_offer/TradeOfferModel"
import DiscountOfferModel, {
  ProductWithDiscountAndTax
} from "../../model/discount_offer/DiscountOfferModel"
import AmountHelpers from "../../utils/AmountHelpers"
import CommonDialog from "../../common/CommonDialog"

const styles = (theme: Theme) => ({
  productGrid: {
    backgroundColor: theme.palette.primary.light,
    padding: "0 24px"
  },
  header: {
    padding: 1
  },
  img: {
    objectFit: "contain",
    borderRadius: 8,
    height: 60
  } as React.CSSProperties,
  name: {
    paddingLeft: 1
  },
  detail: {
    padding: "4px 2px 4px 2px"
  },
  typography: {
    fontSize: 16,
    fontWeight: 600
  }
})

const CartItem = ({ cartItem }: { cartItem: CartAttributes }) => {
  const ctnRef = useRef<null | HTMLInputElement>(null)
  const pcsRef = useRef<null | HTMLInputElement>(null)
  const classes = styles(useTheme())
  const cartContext = useContext(CartContext) as CartContextType
  const [pcsQty, setPcsQty] = useState<string>(
    cartItem?.pcsQty?.toString() ?? "0"
  )
  const [ctnQty, setCtnQty] = useState<string>(
    cartItem?.ctnQty?.toString() ?? "0"
  )
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const getTradeOfferDiscount = () => {
    return cartItem?.offers?.tradeOffers
      ? TradeOfferModel().calculate({
          tradeOffers: cartItem.offers.tradeOffers,
          product: cartItem.product,
          totalQty: calculateTotalQty({
            ctnQty: parseInt(ctnQty),
            pcsQty: parseInt(pcsQty),
            product: cartItem.product
          })
        })
      : { discount: "0", freeUnits: "0 free units" }
  }

  useEffect(() => {
    handleCartItems()
  }, [])

  useEffect(() => {
    if (cartItem.ctnQty?.toString() !== ctnQty)
      setCtnQty(cartItem.ctnQty.toString())
    if (cartItem.pcsQty?.toString() !== pcsQty)
      setPcsQty(cartItem.pcsQty.toString())
  }, [cartItem])

  useEffect(() => {
    if (cartContext?.cartItems) {
      const productsWithDiscount: ProductWithDiscountAndTax =
        DiscountOfferModel().calculateTotal({
          cartItems: cartContext.cartItems,
          schemeLimits: cartContext.schemeLimits
        })

      cartContext?.addOrUpdateDiscountPriceAndTax(productsWithDiscount)
    }
  }, [cartItem.ctnQty, cartItem.pcsQty])

  useEffect(() => {
    handleCartItems()
  }, [pcsQty, ctnQty])

  useEffect(() => {
    pcsRef?.current?.blur()
    ctnRef?.current?.blur()
  }, [openDialog])

  const handleCartItems = () => {
    cartContext.updateCartItem({
      netAmount: calculatePrice(cartItem),
      product: cartItem.product,
      pcsQty: parseInt(pcsQty),
      ctnQty: parseInt(ctnQty),
      offers: cartItem.offers,
      groupedDiscountOffer: cartItem.groupedDiscountOffer,
      tradeOfferDiscount: getTradeOfferDiscount(),
      tax: cartItem.tax,
      discount: cartItem.discount
    })
  }

  const handleCtnQtyChange = (newQty: string) => {
    if (parseInt(newQty) === 0 && cartItem.pcsQty === 0) setOpenDialog(true)
    else setCtnQty(newQty)
  }

  const handlePcsQtyChange = (newQty: string) => {
    if (parseInt(newQty) === 0 && cartItem.ctnQty === 0) {
      setOpenDialog(true)
    } else setPcsQty(newQty)
  }

  const getTradeOffer = (tradeOfferDiscount: TradeOfferDiscount): string => {
    if (tradeOfferDiscount.freeUnits === "0 free units")
      return `Rs. ${AmountHelpers().formatToCurrency(
        parseFloat(tradeOfferDiscount.discount)
      )}`
    return tradeOfferDiscount.freeUnits
  }

  const calculatePrice = (item: CartAttributes) => {
    const discount = item.discount ? AmountHelpers().format(item.discount) : 0
    const tax = item.tax ? AmountHelpers().format(item.tax) : 0
    const tradeOfferDiscount = item.tradeOfferDiscount.discount
      ? AmountHelpers().format(parseFloat(item.tradeOfferDiscount.discount))
      : 0

    return (
      AmountHelpers().format(parseFloat(item.product.tpWoTax)) *
        calculateTotalQty({
          ctnQty: item.ctnQty,
          pcsQty: item.pcsQty,
          product: item.product
        }) -
      discount +
      tax -
      tradeOfferDiscount
    )
  }

  const getDiscount = (item: CartAttributes) => {
    return item?.discount ? item.discount : 0.0
  }

  const handleClose = () => setOpenDialog(false)

  const handleOk = () => {
    setOpenDialog(false)
    setCtnQty("0")
    setPcsQty("0")
  }

  return (
    <>
      <Grid container item xs={12} sm={6} lg={4} sx={classes.productGrid}>
        <Grid
          container
          item
          sx={classes.header}
          alignItems="flex-start"
          wrap="nowrap"
        >
          <Grid item xs={3}>
            <img
              width="100%"
              style={classes.img}
              src={
                cartItem.product.image ? cartItem.product.image : ImgNotFound
              }
              alt="product_image"
            />
          </Grid>
          <Grid item xs={10} sx={classes.name}>
            <Typography sx={classes.typography}>
              {cartItem.product.skuDesc}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12} sx={{ textAlign: "center" }}>
          <Grid
            container
            item
            xs={12}
            sx={classes.detail}
            flexDirection="row"
            wrap="nowrap"
          >
            <Grid item xs={3}>
              <LabelValue
                title="Retail Price"
                value={`Rs. ${AmountHelpers().formatToCurrency(
                  parseFloat(cartItem.product.mrsp)
                )}`}
              />
            </Grid>
            <Grid item xs={3}>
              <LabelValue
                title="Trade Offer"
                value={getTradeOffer(cartItem.tradeOfferDiscount)}
              />
            </Grid>
            <Grid item xs={6}>
              <ProductQuantity
                title="Ctn"
                qty={ctnQty}
                handleQtyChange={handleCtnQtyChange}
                location="cart"
                qtyRef={ctnRef}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={classes.detail}
            flexDirection="row"
            wrap="nowrap"
          >
            <Grid item xs={3}>
              <LabelValue
                title="Trade Price"
                value={`Rs. ${AmountHelpers().formatToCurrency(
                  parseFloat(cartItem.product.tpWoTax)
                )}`}
              />
            </Grid>
            <Grid item xs={3}>
              <LabelValue
                title="Discount"
                value={`Rs. ${AmountHelpers().formatToCurrency(
                  getDiscount(cartItem)
                )}`}
              />
            </Grid>
            <Grid item xs={6}>
              <ProductQuantity
                title="Pcs"
                qty={pcsQty}
                handleQtyChange={handlePcsQtyChange}
                location="cart"
                qtyRef={pcsRef}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={3}
            sx={classes.detail}
            justifyContent="center"
          >
            <Grid item>
              <LabelValue
                title="Net Amount"
                value={`Rs. ${AmountHelpers().formatToCurrency(
                  calculatePrice(cartItem)
                )}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider />
      <CommonDialog
        handleClose={handleClose}
        handleOk={handleOk}
        open={openDialog}
        title={"Remove Product"}
        body={" Are you sure to remove this product from cart?"}
      />
    </>
  )
}

export default CartItem
