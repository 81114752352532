import React, { useEffect } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/client"
import { useLocation } from "react-router-dom"
import {
  addToStorage,
  LocalStorageKeys,
  getFromStorage,
  removeFromStorage
} from "../../../utils/LocalStorage"
import { Grid } from "@mui/material"
import ValidateWhatsAppPage from "./ValidateWhatsAppPage"
import InvalidSessionPage from "./InvalidSessionPage"
import DateTimeHelpers from "../../../utils/DateTimeHelpers"
import { WaSessionType } from "../../../@types/waSessionTypes"

const useQueryParam = () => {
  return new URLSearchParams(useLocation().search)
}

const WaSession: React.FC<WaSessionType> = ({ handleValidate }) => {
  const query = useQueryParam()
  const waSessionId = query.get("waSessionId")
  const localStorageSession = getFromStorage(LocalStorageKeys.waSession)

  const [getWaSession, { data, loading, error }] = useLazyQuery(WASESSION, {
    variables: { bvid: waSessionId },
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (!waSessionId && validateExpiry(localStorageSession?.expiry))
      handleValidate(true)
    else if (
      localStorageSession?.waSession?.bvid &&
      localStorageSession?.waSession?.bvid === waSessionId &&
      validateExpiry(localStorageSession.expiry)
    )
      handleValidate(true)
    else if (data?.waSession) handleSession()
    else getWaSession()
  }, [data])

  const validateExpiry = (expiry: any) => {
    const currentTime = new Date().getTime()
    if (expiry < currentTime) {
      removeFromStorage(LocalStorageKeys.waSession)
      return false
    }
    return true
  }

  const handleSession = () => {
    const waData = {
      waSession: data.waSession,
      expiry: DateTimeHelpers().getLaterTime()
    }
    addToStorage(LocalStorageKeys.waSession, waData)
    handleValidate(true)
  }

  return (
    <Grid>
      {loading ? (
        <ValidateWhatsAppPage />
      ) : error ? (
        <InvalidSessionPage />
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default WaSession

const WASESSION = gql`
  query waSession($bvid: ID!) {
    waSession(bvid: $bvid) {
      bvid
      customerWaId
      pop {
        popId
        popName
        accountType
        accountTypeDesc
        isActive
        distributorId
        distributorName
        distributorAddress
        distributorMobile
        distributorCoordinates
        locality
        localityDesc
        subLocality
        subLocalityDesc
        ownerName
        regType
        strn
        popCoordinates
        ntn
        cnic
        contactNumber
        popAddress
        areaType
        areaDesc
        popType
        popTypeDesc
        zone
        zoneCode
        townName
        townId
        strata
        strataDesc
        dsrs {
          dsrName
          dsr
        }
        pjps {
          pjp
          pjpName
        }
        sections {
          section
          sectionName
        }
        sellCategories {
          sellCategory
          sellCategoryName
        }
      }
    }
  }
`
