const AmountHelpers = () => {
  const format = (num: number) => {
    return Math.round(num * 100) / 100
  }
  const formatToCurrency = (num: number) => {
    return num.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }
  return { format, formatToCurrency }
}
export default AmountHelpers
