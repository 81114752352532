import configurations from "./config.json"

interface EnvConfig {
  name: string
  "cp-genesis": { url: string }
  "cp-phoenix": { url: string }
  waba: { id: string }
}

const environment = process.env.REACT_APP_ENV || "local"
const config = configurations[environment as keyof typeof configurations]
if (!config) throw `Configuration not found for environment: ${environment}`

export default {
  ...config,
  name: environment
} as EnvConfig
