import React from "react"
import { Grid } from "@mui/material"
import CategoryList from "./categories/CategoryList"

const Homepage = () => {
  return (
    <Grid>
      <CategoryList />
    </Grid>
  )
}

export default Homepage
