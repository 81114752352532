export const PRODUCT_SIZE = 5
export interface ProductAttributes {
  prod1: string
  product_group: string
  prod2: string
  principal: string
  prod3: string
  category: string
  waCategory: string
  prod4: string
  subCategory: string
  prod5: string
  brand: string
  prod6: string
  grammage: string
  masterSku: string
  masterSkuDesc: string
  sku: string
  skuDesc: string
  standardFactor: string
  batch: string
  sellUnit1: string
  sellWeight1: string
  sellFactor1: string
  sellUnit2: string
  sellWeight2: string
  sellFactor2: string
  sellUnit3: string
  sellWeight3: string
  sellFactor3: string
  prod7: string
  subBrand: string
  saleGroup: string
  saleGroupDesc: string
  prod8: string
  packSize: string
  prod9: string
  prod9Desc: string
  tpWoTax: string
  tax: string
  mrsp: string
  thirdSchedule: string
  image: string
  batchIndex: string
  sellCategory: string
}

export interface OrderProduct {
  sku: string
  skuDesc: string
  tpWoTax: string
  sellCategory: string
  mrsp: string
  batch: string
  ctnSize: number
  ctnQty: number
  pcsQty: number
  tradeOfferQty: number
  tradeOfferValue: number
  discount: number
  gst: number
  totalAmount: number
}
